
  $(document).ready(function() {

    //div element animation
    AOS.init({disable: 'mobile'});

    //Datepicker Begin
    $('.input-group.date-from').datepicker({
      format: 'dd/mm/yyyy',
      startDate: '-0m',
      autoclose: true,
      todayHighlight: true,
      
    }).datepicker('setDate', 'today');

    $('.input-group.date-to').datepicker({
      format: 'dd/mm/yyyy',
      startDate: '-0m',
      autoclose: true,
      todayHighlight: true,
      
    }).datepicker('setDate', '+1d');
    //Datepicker End
    
    //Homepage slider height
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    //Home Slider Carousel Begin
    $('#owl-slider-carousel').owlCarousel({
      items : 1,
      nav:false,
      margin: 0,
      autoplay:false,
    });
    //Home Slider Carousel End

    //Testimonials Carousel Begin
    $('#owl-testimonials-carousel').owlCarousel({
      items : 4,
      nav:false,
      margin: 24,
      autoplay:true,
      autoplayTimeout:6000,
      responsive : {
        0: {
          items: 1.2,
          margin: 0,
        },
        480: {
          items: 2.2,
          margin: 0
        },
        768: {
          items: 3.2,
          margin: 0
        },
        992: {
          items: 4,
          margin: 24
        }
      }
    });
    //Testimonials Carousel End

    //Services Carousel Begin
    $('#owl-services-carousel').owlCarousel({
      items : 4,
      nav:false,
      margin: 24,
      autoplay:true,
      autoplayTimeout:6000,
      responsive : {
        0: {
          items: 1,
          margin: 0,
        },
        480: {
          items: 2,
          margin: 0
        },
        768: {
          items: 3,
          margin: 0
        },
        992: {
          items: 4,
          margin: 24
        }
      }
    });
    //Services Carousel End

    //HomePage Rooms Carousel Begin
    $("#owl-rooms-carousel").owlCarousel({
      center: true,
      loop:true,
      margin: 50,
      navigation : true,
      autoplay:true,
      autoplayTimeout:6000,
      items: 2,
      nav: true,
      navText: ['<i class="fas fa-arrow-left"></i>','<i class="fas fa-arrow-right"></i>'],
      navContainer: '.owl-carousel-controller .owl-nav',
      dotsContainer: '.owl-carousel-controller .owl-dots',
      responsive : {
        0: {
          items: 1,
          margin: 0,
        },
        992: {
          items: 2,
          margin: 50
        }
      }
      
    });
    //HomePage Rooms Carousel End

    //Rooms Page Carousel Begin
    var $roomListCarousel = $(".room-list-items");
    if($roomListCarousel.length > 0){
      $roomListCarousel.each(function( index ) {
        $(`#owl-rooms-item-${index}`).owlCarousel({
          loop:true,
          margin: 0,
          navigation : true,
          autoplay:false,
          autoplayTimeout:3000,
          items: 1,
          nav: true,
          navText: ['<i class="fas fa-arrow-left"></i>','<i class="fas fa-arrow-right"></i>'],
          navContainer: `.owl-carousel-controller #owl-nav-${index}`,
          dotsContainer: `.owl-carousel-controller #owl-dots-${index}`,    
        });
      });
    }
    //Rooms Page Carousel End

    //Image Carousel Begin
    $("#owl-carousel").owlCarousel({
      loop:false,
      margin: 0,
      navigation : true,
      autoplay:true,
      autoplayTimeout:6000,
      items: 3,
      nav: true,
      navText: ['<i class="fas fa-arrow-left"></i>','<i class="fas fa-arrow-right"></i>'],
      responsive : {
        0: {
          items: 1
        },
        480: {
          items: 1
        },
        768: {
          items: 3,
          margin: 60
        },
        992: {
          margin: 120,
        }
      }
    });
    //Image Carousel End 

    //Environment Detail Carousel Begin
    $('#owl-environment-carousel').owlCarousel({
      loop: true,
      items : 3,
      navigation : true,
      autoplay:true,
      autoplayTimeout:6000,
      nav:true,
      margin: 45,
      navText: ['<i class="fas fa-arrow-left"></i>','<i class="fas fa-arrow-right"></i>'],
      responsive : {
        0: {
          items: 1,
          margin: 0,
        },
        480: {
          items: 2,
          margin: 24
        },
        768: {
          items: 3,
          margin: 45
        }
      }
    });
    //Environment Detail Carousel End
  
    //Video Global FancyBox Begin
    $('[data-video-fancybox]').fancybox({
      youtube : {
          controls : 0,
          showinfo : 0
      },
      vimeo : {
          color : 'f00'
      }
    });
    //Video Global FancyBox End
    
    //Images Global FancyBox Begin
    $('[data-fancybox="images"]').fancybox({
      afterLoad : function(instance, current) {
          var pixelRatio = window.devicePixelRatio || 1;
    
          if ( pixelRatio > 1.5 ) {
              current.width  = current.width  / pixelRatio;
              current.height = current.height / pixelRatio;
          }
      }
    });
    //Images Global FancyBox End
    
    //Video Cursor Position Begin
    function cursorFollowAndReturn(options) {
      let settings = $.extend({
        follower: '', // jQuery element
        container: '', // jQuery element
      }, options);
    
      // exit if elements are not found
      if (!settings.follower.length && !settings.container.length) return;
    
      // set button position when mouse move inside wrapper
      settings.container.on("mousemove", function(e) {
        let x = e.pageX,
          y = e.pageY, // mouse offset
          offsetX = settings.container.offset().left, // container offset
          offsetY = settings.container.offset().top,
          valX = x - offsetX - (settings.container.outerWidth() / 2),
          valY = y - offsetY - (settings.container.outerHeight() / 2);
    
        gsap.to(settings.follower, .5, {
          x: valX,
          y: valY
        });
      });
    
      // set button to center of wrapper when mouse out
      settings.container.on("mouseout", function(e) {
        gsap.to(settings.follower, .5, {
          x: 0,
          y: 0
        });
      });
    }
    
    // init
    cursorFollowAndReturn({
      follower: $('.s-video-badge'),
      container: $('.s-video'),
    });
    //Video Cursor Position End

    // Gallery Tabs Begin
    function Tabs() {
      var bindAll = function() {
        var menuElements = document.querySelectorAll('[data-tab]');
        for(var i = 0; i < menuElements.length ; i++) {
          menuElements[i].addEventListener('click', change, false);
        }
      }
    
      var clear = function() {
        var menuElements = document.querySelectorAll('[data-tab]');
        for(var i = 0; i < menuElements.length ; i++) {
          menuElements[i].classList.remove('active');
          var id = menuElements[i].getAttribute('data-tab');
          document.getElementById(id).classList.remove('active');
        }
      }
    
      var change = function(e) {
        clear();
        e.target.classList.add('active');
        var id = e.currentTarget.getAttribute('data-tab');
        document.getElementById(id).classList.add('active');
      }
    
      bindAll();
    }
    
    var connectTabs = new Tabs();
    // Gallery Tabs End

    //All Bind Events
    var $bookNow = $('.header .book-now');
    $(document).on('click', function (e) {
      if(!$bookNow.is(e.target) && $bookNow.has(e.target).length === 0){
          $('.reservation-widget').removeClass('show');
      }
    });

    //Header Book Now Button Click Event Begin
    $('#showBookWidget').on('click', function(){
      $(".reservation-widget").toggleClass("show");
    });
    //Header Book Now Button Click Event End

    //Menu Open Click Event Begin
    $(document).on('change', '.mobile-menu-icon input', function(){
      $(this).closest('.mobile-menu-icon').toggleClass('active');
      $('.header .menu').toggleClass('show');
      $('body').toggleClass('overflow-hidden');
    });
    //Menu Open Click Event End
    
    // Home Intro Scroll Button Event Begin
    $(document).on('click', '.btn-scroll', function(){
      $('html, body').animate({
        scrollTop: $("#rooms").offset().top - 100
      }, 1000);
    });
    // Home Intro Scroll Button Event End

    // Scroll Header Class Begin
    $(document).on("scroll", function() {
      $(document).scrollTop() > 100 || document.documentElement.scrollTop > 100 ? $(".header").addClass("header-scroll") : $(".header").removeClass("header-scroll")
    });
    // Scroll Header Class End


    //SVG Maps Click Events Begin
      var wordStates = document.querySelectorAll(".location-list .item");
      var svgStates = document.querySelectorAll("#maps circle");

      function removeAllOn() {
        wordStates.forEach(function(el) {
          el.classList.remove("on");
        });
        svgStates.forEach(function(el) {
          el.classList.remove("on");
        });
      }

      function addOnFromList(el) {
        var stateCode = el.getAttribute("data-state");
        var svgState = document.querySelector("#" + stateCode);
        el.classList.add("on");
        svgState.classList.add("on");
      }

      function addOnFromState(el) {
        var stateId = el.getAttribute("id");
        var wordState = document.querySelector("[data-state='" + stateId + "']");
        el.classList.add("on");
        wordState.classList.add("on");
      }

      wordStates.forEach(function(el) {
        el.addEventListener("mouseenter", function() {
          addOnFromList(el);
        });
        el.addEventListener("mouseleave", function() {
          removeAllOn();
        });
        
        el.addEventListener("touchstart", function() {
          removeAllOn();
          addOnFromList(el);
        });
      });

      svgStates.forEach(function(el) {
        el.addEventListener("mouseenter", function() {
          addOnFromState(el);
        });
        el.addEventListener("mouseleave", function() {
          removeAllOn();
        });
        
        el.addEventListener("touchstart", function() {
          removeAllOn();
          addOnFromState(el);
        });
      });
    //SVG Maps Click Events end


  }); // end ready



